<script lang="ts" setup>
import {
  renderSVG,
} from 'uqr'

interface ButtonProps {
  icon: string | boolean
  class?: string
  color?: string
  size: string
  text?: string
  value?: string
  variant: 'flat' | 'text' | 'elevated' | 'tonal' | 'outlined' | 'plain'
}

interface MenuProps {
  openOnHover?: boolean | undefined
  openDelay?: number | string | undefined
  location?: any
  activator?: any
}

interface IconProps {
  name?: string
  size?: string
}

const {
  buttonProps,
  copyText,
  hideCopyText = false,
  link,
  menuProps,
  size = 86,
  title,
} = defineProps<{
  copyText: string
  link: string
  size?: number
  title: string
  buttonProps?: ButtonProps
  menuProps?: MenuProps
  iconProps?: IconProps
  hideCopyText?: boolean
}>()

const src = renderSVG(link)

const { copied, copy } = useClipboard()
</script>

<template>
  <v-btn
    :aria-label="title"
    icon
    size="x-small"
    variant="text"
    v-bind="buttonProps"
    @click="copy(copyText || link)"
  >
    <v-fade-transition leave-absolute hide-on-leave>
      <span v-if="!copied">
        <slot>
          <Icon
            class="text-medium-emphasis"
            name="tabler:info-circle"
            size="24"
            v-bind="iconProps"
          />
        </slot>
      </span>
      <span v-else>
        <slot name="copied">
          <Icon
            class="text-success"
            name="tabler:clipboard-check"
            size="18"
            v-bind="iconProps"
          />
        </slot>
      </span>
    </v-fade-transition>

    <v-menu
      activator="parent"
      v-bind="menuProps"
    >
      <v-card
        class="pa-2 rounded-lg elevation-0" variant="flat"
        :width="size + 24"
      >
        <v-toolbar class="d-flex align-center justify-center pb-1" density="compact" height="auto">
          <span class="text-body-2 font-weight-thin mx-auto">{{ title }}</span>
        </v-toolbar>
        <div class="rounded-sm pa-1 bg-white details h-100 w-100 d-flex flex-column overflow-hidden">
          <div
            v-if="src"
            :style="{
              height: `${size}px`,
              width: `${size}px`,
            }"
            v-html="src"
          />
        </div>
        <div v-if="!hideCopyText" class="py-1 text-body-2 text-medium-emphasis font-weight-light text-center">
          <NuxtLink
            v-if="link"
            class="text-decoration-none"
            target="_blank"
            :to="link"
          >
            {{ copyText }}
          </NuxtLink>
          <span v-else>
            {{ copyText }}
          </span>
        </div>
      </v-card>
    </v-menu>
  </v-btn>
</template>

<style lang="scss" scoped>
:deep() {
  .v-toolbar__content > .v-toolbar-title {
    margin-inline-start: 0;
  }
}
</style>
