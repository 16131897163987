const statusToColor: any = {
  'Angebot versendet': 'accent',
  'Risikovorabgefragt': 'warning',
  'abgeschlossen': 'success',
  'accepted': 'success',
  'beraten': 'primary',
  'cancelled': 'error',
  'gebucht': 'surface-bride',
  'offen': 'surface-bride',
  'pending': 'warning',
  'qualifiziert': 'primary-darken-1',
  'reklamiert': 'error',
}

export function useColor() {
  function determineStatusColor(status: string) {
    // Check if the status exists in the mapping and return the corresponding color
    // Return a default color if the status is not found (optional)
    return statusToColor[status] || 'surface-bride'
  }

  return {
    determineStatusColor,
  }
}
